<template>
  <main class="register">
    <label>Lieblingstier*</label>
    <input v-model="annimal" type="text" name="annimal" maxlength="15" />
    <label>Alter</label>
    <select v-model="age">
      <option :value="n + 9" v-for="n in 90" :key="n">{{ n + 9 }}</option>
    </select>
    <label>Email*</label>
    <input v-model="email" type="email" name="email" maxlength="30" />
    <p><input v-model="dsgvo" type="checkbox" /> Datenschutz*</p>
    <p class="notiz">Ich stimme der Speicherung meiner Daten zu. Deine Daten werden allerdings in vier Wochen automatisch vom Server gelöscht.</p>
  </main>
  <teleport to="#buttongroupfooter">
    <button @click="register" class="bigbutton orange">Registrieren</button>
  </teleport>
</template>
<script>
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { postData, baseURL } from "../api";
export default {
  name: "register",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const email = ref("");
    const annimal = ref("");
    const age = ref(1);
    const dsgvo = ref(false);
    async function register() {
      if (store.state.loggedin) {
        if (store.state.takenimages >= store.state.maximages)
          router.push({ name: "overview" });
        else router.push({ name: "camera" });
        return;
      }
      if (email.value == "") {
        store.dispatch("firenotice", {
          notice:
            "Gib deine Email an! So wirst du beim nächsten QR-Code wieder erkannt!",
          notice_type: "alert-danger",
        });
        return;
      }
      if (annimal.value == "") {
        store.dispatch("firenotice", {
          notice:
            "Gib dein Lieblingstier an! So wirst du beim nächsten QR-Code wieder erkannt!",
          notice_type: "alert-danger",
        });
        return;
      }
      if (!dsgvo.value) {
        store.dispatch("firenotice", {
          notice:
            "Du solltest den Datenschutzbestimmungen zustimmen, sonst funktioniert die Technik nicht. <br> Wir werden vertauensvoll mit deinen Daten umgehen ",
          notice_type: "alert-danger",
        });
        return;
      }
      let data = {
        email: email.value,
        annimal: annimal.value,
        age: age.value,
        agent: navigator.userAgent,
        dsgvo: dsgvo.value,
      };
      let res = await postData(baseURL + "api/register", JSON.stringify(data));
      if (typeof res === "object" && res.status == "ok") {
        data.agent = "";
        store.commit("register", {
          account: data,
          token: res.token,
        });
        router.push({ name: "camera" });
      } else {
        store.dispatch("firenotice", {
          notice: res.message,
          notice_type: "alert-danger",
        });
      }
    }

    return {
      register,
      email,
      annimal,
      age,
      dsgvo,
    };
  },
};
</script>
